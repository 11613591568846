import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const TermsPage = () => (
    <Layout>
        <Seo
            title="利用規約"
        />

        <section className="bg-secondry-light p-16">
            <div className="container max-w-5xl mx-auto">
                <div className="flex justify-around">
                    <h1 className="text-2xl text-white font-bold leading-none mb-3">利用規約</h1>
                </div>
            </div>
        </section>

        <section className="bg-base-main p-8">
            <div className="max-w-5xl mx-auto">

                <div className="flex flex-wrap">
                    <div className="">
                        <Link to={`/`}>
                            <button className="text-secondry-main text-sm underline hover:no-underline">TOP</button>
                        </Link>
                        <span className="mx-4 text-sm">></span>
                        <Link to={`/terms/`}>
                            <button className="hover:text-base-dark text-base-dark text-sm underline hover:no-underline">利用規約</button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-base-main p-8">
            <div className="container max-w-5xl mx-auto">

                <div className="py-6 px-4 border-2 border-base-dark bg-white rounded-lg">
                    <div className="text-md py-8 ">
                        とっとり学生活動ポータルサイト利用規約
                        とっとり学生活動ポータルサイト利用規約 (以下、「本規約」とする。)は、 Lifefix 合同会社 (以下、「当社」とする。)が運営・提供する学生活動紹介サイト及び関連するサービス(以下、各サービスの 総称を「本サービス」とする。)を利用する者(以下、「サービス利用者」とする。)との間の権利義務関係を 定めることを目的としています。 本サービスを利用するためには、本規約に同意していただく必要があります。
                        また、本規約は当社及びサービス利用者間の唯一の合意事項となり、他の口頭又は書面による合意に 優先するものとします。ただし、本規約に優先する旨を特記した別紙記載のサービス別の特則や書面による 合意に限って例外的に本規約に優先するものとします。なお、当社が本サービスの提供にあたり別途規定 する「本サービスご利用上の注意点」など、本サービスサイト上に掲載する各種ルールや取り決めも本規約の 一部を構成するものとしますので、併せてご確認いただくとともにサービス利用者は常に本規約の最新版を ご確認ください。
                        第 1 条(本サービスの概要) 当社は本サービスにおいてサービス利用者に以下のサービスを提供します。 1 学生の課外活動、学生団体に関する情報提供
                        2 掲載学生団体とサービス利用者間のコミュニケーションの媒介
                        3 その他前各号に付随するサービス
                        第 2 条(本サービス利用料) サービス利用者の本サービス利用料は無料とします。
                        第 3 条(注意事項)
                        1. 当社はやむを得ない理由がある場合、サービス利用者に対する事前の告知なく、本サービスの内容の一
                        部又は全部の変更、追加及び廃止をすることができるものとします。ただし、本サービスの全部を廃止す る場合には、当社は当社が適当と判断する方法により、廃止の 1 ヶ月前までにサービス利用者に対し てその旨及び登録情報(個人情報を含む)の取扱い等について告知するものとします。
                        2. 当社は、サービス利用者に対して、本サービスに関する各種案内その他の広告を含む電
                        子メール又はメッセージやメールマガジンを送信することがありますので予めご了承いただきます。

                        第 4 条(禁止事項)
                        サービス利用者は、以下の行為を行ってはならないものとします。 (1)当社、第三者の知的財産権(著作権(著作権法第 27 条及び第 28 条に定める権利を含みます。)・ 意匠権・特許権・実用新案権・商標権・ノウハウが含まれますがこれらに限定されません。以下では、総称 し「知的財産権」とする。)、プライバシー、個人情報その他の権利を侵害する行為(ここには、当社や他のサ ービス利用者を含む第三者に不利益もしくは済的損害、精神的損害を与える行為を含みます。)、又は侵 害するおそれのある行為や公序良俗に反する行為 (2)犯罪的行為もしくは犯罪的行為に結びつく行為、又はそのおそれのある行為 (3)本サービスの運営を妨げる、又はそのおそれのある行為 (4)本サービスの信用を毀損する、又はそのおそれのある行為 (5)法令及び省令・規則・行政指導等に違反する、又はそのおそれのある行為 (6)その他、上記各号に付随して当社が不適切と判断する行為
                        第 5 条(利用資格の取り消し) 当社は、サービス利用者が以下のいずれかの事由に該当する場合、事前の通知なく、直ちにサービス利用 資格を取り消すことができるものとします。
                        (1)本規約のいずれかの条項に違反した場合 (2)その他、当該サービスの利用を継続しがたい事由があると当社が判断した場合
                        第 6 条(免責)
                        1. 当社は、本サービスの運営・提供に関して、以下のいずれかの事由に該当する場合をはじめ、いかなる場 合でも、サービス利用者を含むあらゆる第三者が被った一切の損害について賠償する責任を負わないもの とします。 (1)本サービスの利用に関連して、サービス利用者を含むあらゆる第三者との間で紛争が生じたこと、又はそ のおそれが生じたこと (2)インターネット接続サービスの不具合等接続環境に障害が生じたこと、又はそのおそれが生じたこと (3)当社のシステム保守を定期的もしくは緊急に行う必要が生じ、又は当社が設置する電気通信設備の 障害その他やむを得ない事由が生じ、当社の判断により、本サービスの運営・提供の全部又は一部を停 止する必要が生じたこと、又はそのおそれが生じたこと (4)上記各号のほか、自らの合理的な支配の及ばない状況(火事、停電、ハッキング、コンピュータウイルス の侵入、地震、洪水、戦争、通商停止、ストライキ、暴動、物資及び輸送施設の確保不能、サーバの設

                        置場所における第三者によるサーバへの加害事故の発生、又は政府当局による要請や介入を含みますが これに限りません。)により本サービス運営・提供の履行が遅滞、中断、停止が生じたこと、又はそのおそれ が生じたこと
                        2. 当社は、前項の規定により、本サービス運営・提供の全部であるか一部であるかを問わず、停止するに 至った場合には、当社が適当と判断する方法で可能な限り事前にサービス利用者にその旨を通知するよう 努めるものとします。ただし、緊急の場合はこの限りではないものとします。
                        第 7 条(個人情報の取扱い) 1.当社は、本サービスの運営・提供に関連して知り得た個人情報を取り扱う場合、個人情報保護法及び その他ガイドライン、当社プライバシーポリシー(ururlurlurlurll)等に則り、当該情報を取り扱うものとします。 2.前項において当社が了知した個人情報について、本サービスにおいては以下各号に定める目的で利用す るものとし、サービス利用者は予めこの点について同意するものとします。
                        (1)本サービスの運営・提供に関し、サービス利用者を管理するために必要な場合(本人確認等をする場合 を含みます。)
                        (2) 掲載学生団体へ以下の情報を提供する場合イ)提供する個人情報の項目 氏名、所属団体名(学校名、企業名など)、電子メールアドレス等の連絡先 ロ)提供の手段電子メールにパスワード付きのファイルを添付して提 供ハ)提供を受ける者掲載学生団体の代表者 二)個人情報の取扱いに関する契約機密保持契約、業務委託基本契約により個人情報保護法等を順 守した取扱いを義務とする
                        (3)本規約や法律等に基づく権利の行使や義務の履行の場合 (4)本サービスの品質管理・品質向上のために行うマーケティング調査及び分析に利用する場合 (5)本サービスの各種問い合せに対応する場合 (6)本サービスの運営・提供に関する事柄についての連絡、追加サービス等の情報提供を 行う場合
                        (7)本サービスにおけるシステムの維持、不具合対応を行う場合 (8)本サービスの利用契約終了後のサービス利用者の採用状況の確認等の事後管理を行う場合 (9)本規約第 3 条第 2 項に規定する電子メール又はメッセージ及び各種メールマガジンの配信を行う場合 (10)その他、当社がサービス利用者に事前連絡の上、個別に同意をいただいた目的に利用する場合
                        3. 当社は、サービス利用者の個人情報を以下のいずれかの事由に該当する場合を除き、本

                        人以外の第三者に開示しないものとし、かつ本サービスを運営・提供していく上で必要な範囲を超えて利 用しないものとします。 (1)本規約その他によりサービス利用者の同意が得られた場合(掲載学生団体への情報提供を含みます。) (2)本サービスに関して、当社の権利行使に必要な場合 (3)合併、事業譲渡その他の事由による事業の承継の際に事業を承継する者に対して開示する場合 (4)個人情報保護法その他の法令により認められた場合 (5)官公庁等の公的機関から法令に基づき開示を求められた場合
                        第 8 条(秘密保持)
                        1. 当社及びサービス利用者は、本サービスの利用において相手方から開示された情報又は本サービスの他 の会員の情報もしくはパートナー企業の情報のうち合理的に秘密を保持すべき情報について厳に秘密を保 持するものとし、また情報の開示者又は保有者の承諾を得ず第三者に開示漏洩しないものとします。なお 情報に個人情報が含まれる場合、サービス利用者は、個人情報保護法その他のガイドラインに準じた取 扱いを行うものとし、本人の承諾がない限り当該情報を第三者に開示・漏洩をしてはならないものとしま す。
                        第 9 条(反社会的勢力の排除)
                        1. 当社及びサービス利用者は、相手方に対し、自己及び自己の役員等が現在、次の各号の いずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。 (1)暴力団
                        (2)暴力団員
                        (3)暴力団員でなくなった時から 5 年を経過しない者
                        (4)暴力団準構成員
                        (5)暴力団関係企業 (6)企業等を対象に不正な利益を求めて暴力的不法行為等を行うおそれがあり、市⺠生活の安全に脅 威を与える者 (7)社会運動もしくは政治活動を仮装し、又は標ぼうして、不正な利益を求めて暴力的不法行為等を行 うおそれがあり、市⺠生活の安全に脅威を与える者 (8)前各号に掲げる者のほか、暴力団との関係を背景にその威力を用い、又は暴力団と資 金的なつながりを有し、構造的な不正の中核となっている集団又は個人
                        (9)その他前各号に準ずる者

                        2. 当社及びサービス利用者は、相手方に対し、自ら又は第三者を利用して次の各号に該当する行為を 行わないことを確約するものとします。
                        (1)暴力的な要求行為
                        (2)法的な責任を超えた不当な要求行為
                        (3)取引に関して、脅迫的な言動をし、又は暴力を用いる行為 (4)風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する 行為
                        (5)その他前各号に準ずる行為
                        3. 当社及びサービス利用者は、相手方が前各項の確約に反し又は反していると明白かつ合理的に疑わ れる場合、催告その他何らの手続きを要することなく、直ちに利用契約の全部を解除することができるもの とします。
                        4. 当社及びサービス利用者は、自己(自己の役員等を含む)が第 1 項又は第 2 項の確約に反したことに より相手方が損害を被った場合、相手方に生じた損害を賠償する義務を負うことを確約するものとしま す。
                        第 10 条(損害賠償)
                        1. サービス利用者は、本サービスの利用に関連して、あるいは本規約に違反し当社に損害を与えた場合に は、一切の損害を賠償しなければならないものとします。
                        2. 当社は、サービス利用者が本サービスの利用に関連して、サービス利用者に何等かの損害が発生したと しても当社は一切責任を負わず、その賠償につき免責されるものとします。
                        3. 前項に関わらず、万が一消費者契約法その他の法令等により上記一切の免責が無効又は執行不能 と判断された場合には、当社は、故意又は重過失に起因する場合を除き、損害賠償の範囲はサービス利 用者が損害を負う原因となった本サービスの提供により得た利益の総額を上限とし、それを超えて賠償す る責任を負わないものとし、又、付随的損害、間接損害、特別損害、将来の損害及び逸失利益に係る 損害については、賠償する責任を負わないものとします。
                        第 11 条(権利義務の譲渡等)
                        1. サービス利用者は、如何なる事由によるかを問わず、その地位又はその地位に基づく権利若しくは義務 につき、第三者に譲渡、担保設定、利用許諾その他の処分をしてはならないものとします。

                        2. 当社は、本サービス運営者としての地位並びにその地位に基づく権利及び義務を全部であるか一部で あるかを問わず、事前の予告なく第三者へ譲渡、担保設定、利用許諾その他の処分を実施できるものと します。
                        第 12 条(知的財産権等)
                        1. 本サービスや本サイトを構成するすべての素材(文字、写真、イラスト、映像等を含むがこれに限られない ものとします。)に関する一切の権利(所有権、知的財産権、肖像権、パブリシティー権等を含むが、これら に限られないものとします。)は、当社又は正当な権利を有する第三者に帰属していることをサービス利用 者は予め了承するものとします。
                        2. 本サービスの利用に伴い、サービス利用者が当社に提出した情報に関する知的財産権について、サービ ス利用者は当社に対し本サービスの改善や分析のために利用することを予め了承するものとします。
                        第 13 条(協議・準拠法・管轄)
                        1. 本規約に関連して、当社・会員間で紛争が生じた場合には、紛争解決に向けて誠意をもって自律的に 協議し解決するものとします。
                        2. 本規約の解釈に関する準拠法は日本法とします。
                        3. 本規約に関連して、当社・会員間で訴訟の必要が生じた場合は、鳥取簡易裁判所又は鳥取地方裁 判所を第一審の専属的合意管轄裁判所とするものとします。
                        第 14 条(本規約の変更)
                        1. 当社は、以下の場合に本規約を変更できるものとします。 (1)本規約の変更がサービス利用者の一般的利益に適合する場合 (2)本規約の変更が、サービス提供の目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変 更の内容その他の変更に係る事情に照らして合理的なものである場合
                        2. 当社は、前項により本規約を変更した場合には、変更後の本規約を本サイト上に表示し、原則として 別段の意思を本サイトで表示しない限り、当該表示後 7 日間の経過をもってその効力が生じるものとしま す。なお、サービス利用者は当該期間経過後の本サービスの利用により当該変更に同意したものとみなさ れます。
                        附則 2021 年 11 月 15 日制定・施行
                    </div>
                </div>

            </div>
        </section>

    </Layout>
)

export default TermsPage
